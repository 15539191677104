import { intersection } from 'ramda';
import { IDME_CLIENT_ID } from '@peloton/app-config';
import type { ExtLinkEnv } from '@peloton/external-links/models';
import { toHref, toWWWLink } from '@peloton/external-links/models';
import { CP_MTM_SKU, STUDENT_MTM_SKU } from '@peloton/subscription-plans/skus';
import { CP_TRIAL_PATH, STUDENT_TRIAL_PATH } from './config';
import { ID_ME_SEGMENT, ID_ME_STATE, ID_ME_VERIFICATION_ID } from './constants';
import type { Verification } from './Verification';

const concessionarySkus = [CP_MTM_SKU, STUDENT_MTM_SKU];

enum Setting {
  id = 'ecomm/checkout/verification/id',
  segment = 'ecomm/checkout/verification/segment',
}

export const shouldTrackConcessionary = (
  activeRoute: string,
  promotion: string,
  verification: Verification,
  skus: string = '',
): boolean => {
  const isConcessionaryRoute =
    activeRoute.includes(CP_TRIAL_PATH) || activeRoute.includes(STUDENT_TRIAL_PATH);
  const isConcessionarySku = intersection(skus.split(','), concessionarySkus).length > 0;
  if (
    !promotion &&
    verification &&
    verification.segment &&
    (isConcessionaryRoute || isConcessionarySku)
  ) {
    return true;
  }
  return false;
};

export const generateIdMeAuthorizationLink = (
  extLinkEnv: ExtLinkEnv,
  product?: string,
) => {
  const redirectUri = getRedirectUri(extLinkEnv, product);
  const scopes = 'medical,military,nurse,responder,student,teacher';
  const state = setIdMeState();

  const url = `https://groups.id.me/?client_id=${IDME_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scopes=${scopes}&state=${state}`;
  return url;
};

export const getRedirectUri = (extLinkEnv: ExtLinkEnv, product?: string) => {
  if (product) {
    return toHref(toWWWLink(`/idme/callback?product=${product}`), extLinkEnv);
  } else {
    return toHref(toWWWLink('/idme/callback'), extLinkEnv);
  }
};

export const setIdMeState = () => {
  const existingState = sessionStorage.getItem(ID_ME_STATE);

  if (existingState) {
    return existingState;
  } else {
    const state = window.crypto.randomUUID();
    sessionStorage.setItem(ID_ME_STATE, state);
    return state;
  }
};

export const getStoredVerificationData = () => {
  const segment = localStorage.getItem(ID_ME_SEGMENT);
  const id = localStorage.getItem(ID_ME_VERIFICATION_ID);

  if (segment && id) {
    return { segment, id };
  } else {
    return undefined;
  }
};

export const persistId = (value: string, storage = localStorage) =>
  storage && storage.setItem(Setting.id, value);

export const getPersistedId = (storage = localStorage): string => {
  if (storage) {
    return storage.getItem(Setting.id) || '';
  }

  return '';
};

export const persistSegment = (value: string, storage = localStorage) =>
  storage && storage.setItem(Setting.segment, value);

export const getPersistedSegment = (storage = localStorage): string => {
  if (storage) {
    return storage.getItem(Setting.segment) || '';
  }

  return '';
};
