import type { Verification } from '@peloton/concessionary-pricing';
import {
  persistId,
  getPersistedId,
  persistSegment,
  getPersistedSegment,
} from '@peloton/concessionary-pricing';

export enum ActionTypes {
  VerificationSetSegment = 'ecomm/checkout/VERIFICATION_SET_SEGMENT',
  VerificationSetId = 'ecomm/checkout/VERIFICATION_SET_ID',
}

const getDefaultState = () => ({ id: getPersistedId(), segment: getPersistedSegment() });

const reducer = (state: Verification = getDefaultState(), action: Action) => {
  switch (action.type) {
    case ActionTypes.VerificationSetId: {
      const { id } = action.payload;

      persistId(id);

      return {
        ...state,
        id,
      };
    }

    case ActionTypes.VerificationSetSegment: {
      const { segment } = action.payload;

      persistSegment(segment);

      return {
        ...state,
        segment,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  verification: Verification;
};

export const verificationSetId = (id: string) =>
  <const>{
    type: ActionTypes.VerificationSetId,
    payload: { id },
  };

export const verificationSetSegment = (segment: string) =>
  <const>{
    type: ActionTypes.VerificationSetSegment,
    payload: { segment },
  };

export type VerificationSetIdAction = ReturnType<typeof verificationSetId>;
export type VerificationSetSegmentAction = ReturnType<typeof verificationSetSegment>;

type Action = VerificationSetIdAction | VerificationSetSegmentAction;
