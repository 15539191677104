import { Countries, ProductLine } from '@account/graphql/types.generated';
import type { OrderStatusQuery } from '@account/pg-post-purchase-flow/OrderStatus/OrderStatus.generated';
import type { OrderStatusSharedOrderItemFragment } from '../Receipt/NonBundleItems/NonBundleItems.generated';

export const orderIncludesDigital = (itemSet?: OrderStatusSharedOrderItemFragment[]) => {
  const items = itemSet ? itemSet.filter(i => i.isDigitalSubscription) : [];
  return items.length > 0;
};

export const orderIsStandaloneExtendedWarrantyOrder = (
  itemSet: OrderStatusSharedOrderItemFragment[] = [],
) => {
  return itemSet.length === 1 && itemSet.every(item => itemIsExtendedWarranty(item));
};

export const orderIsBuyoutOnly = (
  itemSet: OrderStatusSharedOrderItemFragment[] | null = [],
) => {
  if (!itemSet) {
    return false;
  }
  const buyoutItems = itemSet.filter(i => isFaasBuyout(i.slug ?? ''));
  /** Buyout orders will contain a buyout sku and an AAM  */
  return buyoutItems.length === 1 && itemSet.length === 2;
};

export const isFaasBuyout = (slug?: string) => {
  return slug && slug.toLowerCase().includes('opc-b-');
};

const itemIsExtendedWarranty = (item: OrderStatusSharedOrderItemFragment) => {
  const skuName = item.skuName!.toLowerCase();
  return extendedWarrantySkus.includes(skuName);
};

export const orderContainsTread = (order: OrderStatusQuery['salesOrder']) => {
  const bundleItems = order!.bundles?.flatMap(bundle => bundle.items) ?? [];
  const items = order!.items ?? [];
  const itemSet = bundleItems.concat(items);
  const treadItems = itemSet.filter(i => isTread(i));
  return treadItems.length > 0;
};

const isTread = (item: OrderStatusSharedOrderItemFragment) => {
  return item.productLine === ProductLine.Tread && !isTreadPlus(item.skuName ?? '');
};

const orderContainsTreadPlus = (order: OrderStatusQuery['salesOrder']) => {
  const bundleItems = order!.bundles?.flatMap(bundle => bundle.items) ?? [];
  const items = order!.items ?? [];
  const itemSet = bundleItems.concat(items);
  const treadPlusItems = itemSet.filter(i => isTreadPlus(i.skuName ?? ''));
  return treadPlusItems.length > 0;
};

const isTreadPlus = (skuName?: string) => {
  return skuName && skuName.toLowerCase().includes('ta01');
};

const TREAD_AAM_ELIGIBILITY_START_DATE = new Date('2024-12-10');
const TREAD_PLUS_AAM_ELIGIBILITY_START_DATE = new Date('2024-12-03');

export const orderEligibleForTreadPlusAAMPromo = (
  order: OrderStatusQuery['salesOrder'],
) => {
  const containsTreadPlus = orderContainsTreadPlus(order);
  const orderCreatedAt = order.createdAt ? new Date(order.createdAt) : null;
  return (
    containsTreadPlus &&
    orderCreatedAt != null &&
    orderCreatedAt > TREAD_PLUS_AAM_ELIGIBILITY_START_DATE
  );
};

export const orderEligibleForTreadAAMPromo = (order: OrderStatusQuery['salesOrder']) => {
  const containsTread = orderContainsTread(order);
  const orderCreatedAt = order.createdAt ? new Date(order.createdAt) : null;
  return (
    containsTread &&
    order.country === Countries.Gb &&
    orderCreatedAt != null &&
    orderCreatedAt > TREAD_AAM_ELIGIBILITY_START_DATE
  );
};

export const getCfuTypeFromWarranty = (warranty: OrderStatusSharedOrderItemFragment) => {
  const skuName = warranty.skuName!.toLowerCase();
  return bikeExtendedWarrantySkus.includes(skuName)
    ? 'Bike'
    : bikePlusExtendedWarrantySkus.includes(skuName)
    ? 'Bike+'
    : treadExtendedWarrantySkus.includes(skuName)
    ? 'Tread'
    : rowExtendedWarrantySkus.includes(skuName)
    ? 'Row'
    : guideExtendedWarrantySkus.includes(skuName)
    ? 'Guide'
    : '';
};

const bikeExtendedWarrantySkus = [
  'ext-bikecpo-warr-us-48m-adh',
  'ext-bike-warr-us-48m-adh',
];

const bikePlusExtendedWarrantySkus = [
  'ext-bikecpo-plus-warr-us-48m-adh',
  'ext-bike-plus-warr-us-48m-adh',
];

const treadExtendedWarrantySkus = ['ext-tread-warr-us-48m-adh'];

const rowExtendedWarrantySkus = ['ext-row-warr-us-48m-adh'];

const guideExtendedWarrantySkus = ['ext-guide-warr-us-24m-adh'];

export const extendedWarrantySkus = [
  bikeExtendedWarrantySkus,
  bikePlusExtendedWarrantySkus,
  treadExtendedWarrantySkus,
  rowExtendedWarrantySkus,
  guideExtendedWarrantySkus,
].flat();
