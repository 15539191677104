import type { AnalyticsBasePropertiesQuery } from '@account/analytics/queries/AnalyticsBaseProperties.generated';
import * as Types from '@account/graphql/types.generated';
import { ProductLine } from '@account/graphql/types.generated';
import type { OrderStatusSharedOrderItemFragment } from '@account/order/Receipt/NonBundleItems/NonBundleItems.generated';
import type { OrderStatusQuery } from '@account/pg-post-purchase-flow/OrderStatus/OrderStatus.generated';
import type { OrderStatusGuestQuery } from '@account/pg-post-purchase-flow/OrderStatus/OrderStatusGuest.generated';
import type { OrderStatusBundleFragment } from '../../order/Receipt/Bundles/Bundles.generated';
import { hasRefurbishedBike, hasRefurbishedBikePlus } from './toTrackingProperties';

enum Category {
  Bike = 'bike',
  BikePlus = 'bike-plus',
  Tread = 'tread',
  TreadPlus = 'tread-plus',
  Row = 'row',
  Accessory = 'accessory',
}

export const getAllItems = (
  salesOrder:
    | Pick<OrderStatusQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<OrderStatusGuestQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<AnalyticsBasePropertiesQuery['salesOrder'], 'bundles' | 'items'>,
) => {
  const items: OrderStatusSharedOrderItemFragment[] = [];
  salesOrder.bundles?.forEach((bundle: OrderStatusBundleFragment) =>
    bundle.items.forEach(item => items.push(item)),
  );
  return [...(salesOrder.items || []), ...items];
};

export const getProductCategories = (
  salesOrder:
    | Pick<OrderStatusQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<OrderStatusGuestQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<AnalyticsBasePropertiesQuery['salesOrder'], 'bundles' | 'items'>,
) => {
  const allItems = getAllItems(salesOrder);
  const skus = allItems.map(item => item.skuName);

  const categories = [];
  (orderHasBike(allItems) || hasRefurbishedBike(skus)) && categories.push(Category.Bike);
  (orderHasBikePlus(allItems) || hasRefurbishedBikePlus(skus)) &&
    categories.push(Category.BikePlus);
  orderHasTread(allItems) && categories.push(Category.Tread);
  orderHasTreadPlus(allItems) && categories.push(Category.TreadPlus);
  orderHasRow(allItems) && categories.push(Category.Row);
  orderHasAccessory(allItems) && categories.push(Category.Accessory);
  return categories;
};

export const orderHasBike = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  const bikeSkus = [
    'bike_v1_ruby',
    'bike_v1_ruby',
    'bike-eu',
    'bike-de',
    'BA01-0007',
    'BA01-0010',
    'BA01-0011',
    'BA01-0012',
  ];
  return (
    itemSet.filter(item => item.skuName && bikeSkus.includes(item.skuName)).length > 0
  );
};

export const orderHasBikePlus = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  const bikePlusSkus = [
    'BA02-0003',
    'BA02-0011',
    'BA02-0011',
    'BA02-0012',
    'BA02-0012',
    'BA02-0020',
    'BA02-0023',
    'BA02-0021',
    'BA02-0022',
    'BA02-0027',
  ];
  return (
    itemSet.filter(item => item.skuName && bikePlusSkus.includes(item.skuName)).length > 0
  );
};

const orderHasTread = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  const treadSkus = ['TA02-0001', 'TA02-0001', 'TA02-0002', 'TA02-0002'];
  return (
    itemSet.filter(item => item.skuName && treadSkus.includes(item.skuName)).length > 0
  );
};

const orderHasTreadPlus = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  const treadPlusSkus = ['tread-plus-na'];
  return (
    itemSet.filter(item => item.skuName && treadPlusSkus.includes(item.skuName)).length >
    0
  );
};

const orderHasRow = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  return itemSet.filter(item => item.productLine === ProductLine.Row).length > 0;
};

const orderHasAccessory = (itemSet: OrderStatusSharedOrderItemFragment[]) => {
  const accessoryProductLines = [
    Types.ProductLine.BikeAccessory,
    Types.ProductLine.TreadAccessory,
    Types.ProductLine.Other,
  ];
  return (
    itemSet.filter(
      item => item.productLine && accessoryProductLines.includes(item.productLine),
    ).length > 0
  );
};

export const getProductNames = (
  salesOrder:
    | Pick<OrderStatusQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<OrderStatusGuestQuery['salesOrder'], 'bundles' | 'items'>
    | Pick<AnalyticsBasePropertiesQuery['salesOrder'], 'bundles' | 'items'>,
): string[] => {
  const allItems = getAllItems(salesOrder);
  return allItems.map(item => item.title);
};

export const getBundleNames = (bundles: OrderStatusBundleFragment[]): string[] => {
  return bundles.map(item => item.title);
};
