import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route as FeatureRoute, FEATURES } from '@account/optimizely';

const BASE_MEMBERSHIP_CANCEL_ROUTE = '/membership/cancel';

const Routes = [
  <FeatureRoute
    feature={FEATURES.IsDeLegalCancellationEnabled}
    key={BASE_MEMBERSHIP_CANCEL_ROUTE}
    path={BASE_MEMBERSHIP_CANCEL_ROUTE}
    component={asyncComponent(() => import('./Page'))}
  />,
];

export default Routes;
