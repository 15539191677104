export enum AnalyticsEventsEnum {
  Started = 'ID Verification Started',
  Success = 'ID Verification Successful',
}

export const ID_ME_STATE = 'id_me_state';
export const ID_ME_SEGMENT = 'ecomm/checkout/verification/segment';
export const ID_ME_VERIFICATION_ID = 'ecomm/checkout/verification/id';

export enum IdMeCohort {
  Student = 'student',
  Teacher = 'teacher',
  FirstResponder = 'responder',
  Military = 'military',
  Medical = 'medical',
  Nurse = 'nurse',
}
